.dropdown {
  position: absolute;
  z-index: 9;
  top: 25px;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  max-width: 500px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 3px 7px;

  border: 0;
  color: #000;
  font-size: 12px;

  &--left {
    left: calc(100% - 10px);
  }

  &--right {
    right: 0;
  }

  &_item {
    width: 100%;
    padding: .25rem 1.5rem;
    font-weight: 400;
    color: #212529;
    background-color: transparent;
    border: 0;
    text-align: left;
    cursor: pointer;

    @extend .ellipsis;

    &:hover {
      background-color: rgba(65,108,150,.1);
      color: #000;
    }

    &--active {
      color: #fff;
      background-color: #007bff;
    }
  }

  &_divider {
    border-top: 1px solid #e2e2e2;
  }
}