.search-box {
  &_title {
    padding: 15px 0 30px;
    font-size: 30px;
    font-weight: 600;
    min-height: 90px;

    @media (max-width: $screen-sm) {
      font-size: 26px;
      padding: 10px 0 20px;
      min-height: 70px;
    }

    @media (max-width: $screen-xs) {
      font-size: 21px;
    }
  }

  &_bar {
    height: 38px;
    border-radius: 3px;
    background-color: #EBEBEB;
    color: #495057;
    width:100%;
    position: relative;

    & input {
      padding: 0 35px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 0;
      font-size: 14px;
      @extend .ellipsis;
  
      &::placeholder {
        color: #9B9B9B;
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  &_icon-search {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &_icon-close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 0 3px 0;

    font-size: 13px;
  } 

  &_search-item {
    margin-left: 7px;
    color: #296CCC;
    text-decoration: underline;
    cursor: pointer;
  }
  
  &_sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: relative;

    cursor: pointer;
  }

  &_sort-icon {
    padding-left: 4px;
    cursor: pointer;
  }

  &_export {
    display: flex;
    align-items: center;
  }

  &_loader {
    max-height: 18px;
  }
}