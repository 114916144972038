.button {
  border: 0;
  border-radius: 5px;

  cursor: pointer;

  font-size: 15px;

  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 1.5;
  padding: 5px 28px;

  background-color: #BDBDBD;
  color: white;

  @media (max-width: $screen-xs) {
    font-size: 12px;
    padding: 4px 15px;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled, &[disabled] {
    cursor: no-drop;
    opacity: .7;
  }

  &--blue {
    background-color: #296CCC;
  }
}
