.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1140px;
  margin: 0 auto;

  font-family: 'Quicksand', sans-serif;
  color: #4E4E4E;

  height: 100%;

  @media (max-width: $screen-lg) {
    padding: 0 15px;
  }

  &_title {
    display: flex;
    align-items: center;

    font-size: 23px;
    font-weight: 100;
    color: #E1E1E1;
    cursor: pointer;
    text-decoration: none;

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: white;
    }

    @media (max-width: $screen-sm) {
      font-size: 20px;
    }
  }

  &_logo {
    width: 40px;
    height: auto;
    padding-right: 10px;
  }

  &_button-box {
    display: flex;
    align-items: center;

    position: relative;

    height: 100%;

    @media (max-width: $screen-sm) {
      font-size: 12px;
    }
  }

}