.rcl-container {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.navbar {
  flex: 0 0 50px;
  background-color: #444444;
}

.main {
  flex: 1 1 auto;

  width: 100%;
  display: flex;
  overflow-y: auto;
}

.content-wrapper {
  flex: 1 1 auto;

  display: flex;
  
  width: calc(1140px + 50vw - 570px);

  padding: 15px 0;
  overflow-x: hidden;
  overflow-y: auto;

  line-height: 1.5;

  @media (max-width: $screen-sm) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.1);
    outline: 1px solid slategrey;
    border-radius: 5px;
    outline: none;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: lightgray;
  }

  &::-webkit-scrollbar-corner {
    background-color: #eee;
  }
}

.content {
  width: 100%;

  @media (max-width: $screen-sm) {
    padding: 0 15px;
  }
}