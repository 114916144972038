.list-item {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &:last-child {
    padding-bottom: 20px;
  }

  &_title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    font-family: 'Quicksand', sans-serif;
    color: #006CD3;
    cursor: pointer;

    @media (max-width: $screen-xs) {
      font-size: 16px;
    }
  }

  &_subtitle-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #6B6B6B;

    margin: 6px 0;

    white-space: nowrap;

    & > * {
      margin-bottom: 2px;
    }

    & > *:not(:last-child) {
      margin-right: 10px;
    }

    @media (max-width: $screen-xs) {
      margin: 4px 0;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &_author {
    color: #4E5371;
    text-decoration: underline;
    cursor: pointer;
  }

  &_content {
    color: #2E2E2E;

    @media (max-width: $screen-xs) {
      font-size: 13px;
      line-height: 15px;
    }
  }

  &_button-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 28px;

    & > * {
      margin-top: 4px;
    }

    .badge {
      font-size: 12px;
      margin-left: 10px;

      @media (max-width: $screen-xs) {
        margin-left: 0;
      }

      a {
        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
          color: inherit;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}