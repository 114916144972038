.sidebar {
  flex: 0 0 calc(50vw - 570px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  padding: 30px 0 0 20px;

  color: #373737;

  @media (max-width: $screen-sm) {
    display: none;
  }
}